import { getUser } from "../utils/auth";
import TestingRecord from "../models/TestingRecord"

import { getCollectionByName } from "../utils/firebase";

const testingHistoryCollection = getCollectionByName("testingHistory");

export function loadTestingRecords() {
    const allCourses = getUser().allCourses;

    return testingHistoryCollection
        .where("userId", "==", getUser().uid)
        .get()
        .then((querySnapshot) => {
            const testingRecords = [];

            querySnapshot.forEach((doc) => {
                testingRecords.push({
                    ...doc.data(),
                    id: doc.id
                });
            });

            return groupTestingRecordsByCourse(testingRecords, allCourses);
        })
}

function groupTestingRecordsByCourse(testingRecords, allCourses) {
    var testedCourses = [];
    let testedCoursesIds = [];

    testingRecords.forEach(record => {
        if (testedCoursesIds.indexOf(record.courseId) === -1) {
            testedCoursesIds.push(record.courseId);
        }
    });

    testedCourses = allCourses.filter(item => testedCoursesIds.includes(item.id));

    return testedCourses.map(testedCourse => {
        const testingRecordsByCourse = testingRecords.filter(record => record.courseId === testedCourse.id)
            .sort((a, b) => {
                return a.date - b.date
            })
            .slice(-3);

        return {
            courseName: testedCourse.name,
            testingRecords: testingRecordsByCourse
        };
    });
}

export function addTestingRecord(results, data, time) {
    // let today = new Date();
    // let date = today.getHours() + ":" + (today.getMinutes() < 10 ? "0" + today.getMinutes() : today.getMinutes()) + " - " + today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
    let date = new Date().getTime();
    let point = getCorrectAnswers(results, data);
    let total = data.data.length;

    const testingRecord = new TestingRecord();
    testingRecord.date = date;
    testingRecord.point = point;
    testingRecord.courseId = data.courseId;
    testingRecord.total = total;
    testingRecord.time = formatTime(time);

    testingRecord.userId = getUser().uid;

    return testingHistoryCollection.add(Object.assign({}, testingRecord));
}


export const formatTime = time => {
    if (time < 60) {
        return time < 10 ? `0${time}s` : `${time}s`;
    } else {
        return Math.floor(time / 60) + 'm' + (time % 60) + 's';
    }
}

export function getCorrectAnswers(results, data) {
    let correct = 0;
    results.forEach((result, index) => {
        if (result.a === data.data[index].answer) {
            correct++;
        }
    });
    return correct;
}

export const timestampToDate = timestamp => {
    let date = new Date(timestamp)
    return date.getHours() + ":" + (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + " - " + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
}