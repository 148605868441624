import { getUser } from "../utils/auth";
import LearnedLesson from "../models/LearnedLesson";
import { LESSON_TYPE } from "../constants/LessonType";
import getFirebase, { getCollectionByName } from "../utils/firebase";


const firebase = getFirebase();
const learningProgressCollection = getCollectionByName("learningProgress");

export function loadLearningProgress() {
    const allCourses = getUser().allCourses;

    return learningProgressCollection.where("userId", "==", getUser().uid)
        .get()
        .then((querySnapshot) => {
            const learnedLessons = [];

            querySnapshot.forEach((doc) => {
                learnedLessons.push({
                    ...doc.data(),
                    id: doc.id
                });
            });

            return createLearnedCoursesFromLessons(allCourses, learnedLessons);
        })
}

export function addOrUpdateLearningProgressItem(lessonId, courseId, type, point, total) {
    const learnedLesson = new LearnedLesson();
    const userId = firebase.auth().currentUser.uid;

    learnedLesson.id = lessonId;
    learnedLesson.userId = userId;
    learnedLesson.courseId = courseId;

    if (type === LESSON_TYPE.QUIZ) {
        learnedLesson.type = type;
        learnedLesson.point = point;
        learnedLesson.total = total;
    }

    return learningProgressCollection
        .doc(userId + lessonId)
        .set(Object.assign({}, learnedLesson));
}

function createLearnedCoursesFromLessons(allCourses, learnedLessons) {
    var learnedCourses = [];
    let learnedCourseIds = [];

    learnedLessons.forEach(lesson => {
        if (learnedCourseIds.indexOf(lesson.courseId) === -1) {
            learnedCourseIds.push(lesson.courseId);
        }
    });

    learnedCourses = allCourses.filter(item => learnedCourseIds.includes(item.id));

    learnedCourses.map(learnedCourse => {
        const learnedLessonCount = learnedLessons.filter(lesson => lesson.courseId === learnedCourse.id).length;
        const finishedQuiz = learnedLessons.find(lesson => lesson.courseId === learnedCourse.id && lesson.type === LESSON_TYPE.QUIZ);

        learnedCourse.progress = parseFloat((learnedLessonCount * 100) / learnedCourse.lessonCount).toFixed(2);
        learnedCourse.percentage = `${learnedCourse.progress}%`;

        if (finishedQuiz) {
            learnedCourse.rating = finishedQuiz.point * 5 / finishedQuiz.total;
        }

        return learnedCourse
    });

    return learnedCourses;
}
